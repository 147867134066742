import React, { useEffect } from "react";
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from './footer_about.module.css'
import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";


const FooterAbout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const page = {
    pages: [
      {
        link: "/",
        content: "Haqqımızda",
      },

    ],
  };

  const title = "Avtopro - Haqqımızda | avtopro.az";
  const keywords = "haqqımızda, avtomobil təmiri, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Avtopro ehtiyat hissələrinin satışını edən veb saytdır. Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir. Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} />
      <Menu />
      <Breadcrumb page={page} none='dnone' />
      <section className="container">
        <Link className={`${styles.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="2rem" /></Link>
        <h1 className={`${styles.head}`} >
          Haqqımızda</h1>
        <div className={`${styles.contents}`}>
          <p>Avtopro ehtiyat hissələrinin satışını edən veb saytdır.
            Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir.
            Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir. </p>
          <p>Saytımızdan alış-veriş etməklə siz kifayət qədər sərfəli qiymətlərlə, vaxt itirmədən, trafikdə qalmadan, onlayn sifariş və çatdırılma imkanı ilə avtomobiliniz üçün ehtiyat hissələri əldə edə bilərsiniz. Biz də öz növbəmizdə sizin kimi daimi müştəri və ya etibarlı biznes tərəfdaşı əldə etməyə ümid edirik.</p>
          <p>Alıcılar saytda olan etibarlı satıcıdan ən yaxşı, ən əlverişli və sərfəli təklifləri seçir və bir neçə kliklə təhlükəsiz şəkildə sifariş edə və ya yerində ala bilərlər.</p>
          <p>Satıcılar bir neçə kliklə bizim saytda öz dükanını yaradıb, eyni gündə ilk sifarişləri qəbul edə və yüzlərlə müştərini cəlb edə bilərlər.</p>
          <h3>
            Avtopro hansı problemləri həll edir?
          </h3>
          <ul className={`${styles.about__list}`}>
            <li>Vaxta qənaət edirsiz</li>
            <li>Trafikdə qalmırsız</li>
            <li>Axtarış rahatlaşır</li>
            <li>Qiyməti bilirsiz</li>
          </ul>


          <h3>Avtopro sizə nə vəd edir?</h3>
          <ul className={`${styles.about__list}`}>
            <li>Vaxt qənaəti</li>
            <li>Pul qənaəti</li>
            <li>Sürətli və güvənilir çatdırılma</li>
            <li>Onlayn sifariş imkanı</li>
            <li>Yerində almaq imkanı</li>
            <li>Keyfiyyətli məhsul</li>
          </ul>

          <h3>Nəyə görə məhz Avtopro?</h3>
          <ul className={`${styles.about__list}`}>
            <li>Mütəxəssislərimiz tərəfindən seçilmiş sadə və aydın interfeys.</li>
            <li>Hər gün minlərlə insan Avtopro-da məhsul axtarır.</li>
            <li>Peşəkar, təsdiqlənmiş ustalar</li>
            <li>Sayt vasitəsilə 100% güvənilir alış</li>
            <li>Keyfiyyətli çatdırılma xidməti</li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default FooterAbout;
