import React, { useContext } from 'react'
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Layout from "../layouts/index"
import Detail from '../pages/detail/detail';
import AutoDetail from '../pages/detailauto/detail';
import Home from '../pages/home';
import Shop from '../pages/shops';
import Autosalon from '../pages/autosalon';
import Autosaloninner from '../pages/autosalon/shopinner/shopinner';
import Shopinner from '../pages/shops/shopinner/shopinner';
import ProductVideo from '../pages/productvideo/productvideo';
import Repairer from '../pages/repairer';
import RepairerDetail from '../pages/repaiererdetail';
import Service from '../pages/service';
import Oils from '../pages/oils';
import Dealers from '../pages/dealers';
import DealerInner from '../pages/dealers/dealerrInner/dealerInner';
import Cardealers from '../pages/cardealers';
import OrderFromAbroad from '../pages/order-abroad';
import RegisterRepair from '../pages/repairer/repairer-register/register';
import Products from '../pages/products';
import NotFound from '../pages/404';
import Individual from '../pages/individual';
import Technique from '../pages/technique';
import ProductResult from '../pages/resultSearch';
import FooterAbout from '../layouts/footer/footerPrivacy/footer_about';
import FooterPrivacy from '../layouts/footer/footerPrivacy/footer_privacy';
import FooterTerms from '../layouts/footer/footerPrivacy/footer_terms';
import FooterHelp from '../layouts/footer/footerPrivacy/footer_help';
import CarNumber from '../pages/carnumber';
import CarDetail from '../pages/carnumber/cardetail';
import Register from '../pages/registration';
import Login from '../pages/registration/login';
import ForgotPassword from '../pages/forgotPassword';
import VerifyCode from '../pages/verifyCode';
import SetPassword from '../pages/setPassword';
import { AuthContext } from '../context/AuthContext';
import Announcement from '../pages/announcement';
import Profil from '../pages/profil';
import PaymentSuccess from '../pages/payment/paymentsuccess';
import PaymentCancel from '../pages/payment/paymentcancel';
import PaymentUnsuccess from '../pages/payment/paymentunsuccess';
import GoMap from '../pages/goMap';
import WishList from '../pages/carts/wishlist/wishlist';
import Cartlist from '../pages/carts/cartlist';
import OrderList from '../pages/carts/orderlist/orderlist';
import PrivateInfo from '../pages/carts/privateinfo/privateinfo';
import MyCards from '../pages/carts/myCards';
import MyBalance from '../pages/carts/myBalance';
import CashBack from '../pages/carts/cashback';


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext)

  if(!isAuthenticated) {
    return <Navigate to="/register" />;
  }
  
  return children;
};


const ProtectedAuth = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated } = useContext(AuthContext)


  if("/register" === location.pathname && isAuthenticated) {
    return <Navigate to="/cart/cartlist" />;
  }


  return children;
}


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      // {
      //   path: "/login",
      //   element: <Login />
      // },
      {
        path: "/forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "/verify-code",
        element: <VerifyCode />
      },
      {
        path: "/reset-password",
        element: <SetPassword />
      },
      {
        path: "/dealers",
        element: <Dealers />
      },
      {
        path: "/dealers/:dealerId",
        element: <DealerInner />
      },
      {
        path: "/cardealers",
        element: <Cardealers />
      },
      {
        path: "/autosalon",
        element: <Autosalon />
      },
      {
        path: "/autosalon/:shopId",
        element: <Autosaloninner />
      },
      {
        path: "/shops",
        element: <Shop />
      },
      {
        path: "/shops/:shopId",
        element: <Shopinner />
      },
      {
        path: "/products",
        element: <Products />
      },
      {
        path: "/products/:productId",
        element: <Detail />
      },
      {
        path: "/product",
        element: <ProductResult />
      },
      {
        path: "/detailauto/:productId",
        element: <AutoDetail />
      },
      // {
      //   path: "/card",
      //   element: <CardInfo />
      // },
      {
        path: "/video",
        element: <ProductVideo />
      },
      {
        path: "/ustalar",
        element: <Repairer />
      },
      {
        path: "/ustalar/:slug",
        element: <RepairerDetail />
      },
      {
        path: "/ustalar/qeydiyyat",
        element: <RegisterRepair />
      },
      {
        path: "/services",
        element: <Service />
      },
      {
        path: "/oils",
        element: <Oils />
      },
      {
        path: "/orderabroad",
        element: <OrderFromAbroad />

      },
      {
        path: "/tezlikle",
        element: <Individual />
      },
      {
        path: "/tezlikle",
        element: <Technique />
      },

      {
        path: "/footer_about",
        element: <FooterAbout />
      },
      {
        path: "/footer_privacy",
        element: <FooterPrivacy />
      },
      {
        path: "/footer_terms",
        element: <FooterTerms />
      },
      {
        path: "/footer_help_center",
        element: <FooterHelp />
      },
      {
        path: "/licenseplate",
        element: <CarNumber />
      },
      {
        path: "/licenseplate/:id",
        element: <CarDetail />
      },

      {
        path: "/tezlikle",
        element: <Individual />
      },
      {
        path: "/tezlikle",
        element: <Technique />
      },
      {
        path: "/register",
        element: <ProtectedAuth>
          <Register />
        </ProtectedAuth>
      },
      {
        path: "/elan",
        element: <Announcement />
      },
      {
        path: "/profil",
        element: <Profil />
      },
      {
        path: "/paymentsuccess",
        element: <PaymentSuccess />
      },
      {
        path: "/paymentcancel",
        element: <PaymentCancel />
      },
      {
        path: "/paymentunsuccess",
        element: <PaymentUnsuccess />
      },
      {
        path: "/gomap",
        element: <GoMap />
      },
      {
        path: "/cart/cartlist",
        element: <ProtectedRoute>
          <Cartlist />
        </ProtectedRoute>

      },
      {
        path: "/cart/orderlist",
        element: <ProtectedRoute>
          <OrderList />
        </ProtectedRoute>
      },
      {
        path: "/cart/wishlist",
        element: <ProtectedRoute>
          <WishList />
        </ProtectedRoute>
      },
      {
        path: "/cart/mycards",
        element: <ProtectedRoute>
          <MyCards />
        </ProtectedRoute>
      },
      {
        path: "/cart/privateinfo",
        element: <ProtectedRoute>
          <PrivateInfo />
        </ProtectedRoute>
      },
      {
        path: "/cart/mybalance",
        element: <ProtectedRoute>
          <MyBalance />
        </ProtectedRoute>
      },
      {
        path: "/cart/cashback",
        element: <ProtectedRoute>
          <CashBack />
        </ProtectedRoute>
      },
      {
        path: "/not-found",
        element: <NotFound />
      },
      {
        path: "*",
        element: <NotFound />
      },
      {
        path: "/products/:productId/*",
        element: <NotFound />
      }
    ],
  },
]);