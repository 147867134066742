import React, { useEffect } from "react";
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from "./footer_about.module.css";
import {
  privacy2_Footer,
  privacy_Footer,
  privacy_Lock,
} from "../../../assets/icons/Icons";
import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";

const FooterPrivacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const page = {
    pages: [
      {
        link: "/footer_privacy",
        content: "Gizlilik siyasəti",
      },
    ],
  };

  const title = "Avtopro - Gizlilik siyasəti | avtopro.az";
  const keywords = "gizlilik siyasəti, privacy and policy, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Avtopro ehtiyat hissələrinin satışını edən veb saytdır. Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir. Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} />
      <Menu />
      <Breadcrumb page={page} none="dnone" />
      <section className="container">
        <Link className={`${styles.lnone}`} to="/">
          <SlArrowLeft color="#181818" fontSize="2rem" />
        </Link>
        <h1 className={`${styles.head} ${styles.headcolor}`}>
          {privacy_Lock} MƏXFİLİK SİYASƏTİ
        </h1>

        <div className={`${styles.comman_wrapper}`}>
          <div className={`${styles.left}`}>
            <div className={`${styles.part1}`}>
              <p>{privacy_Footer}</p>
              <h3>Şəxsi məlumatlar </h3>
              <p>
                Fiziki və ya hüquqi şəxsə ("məlumat subyekti") aid hər hansı bir məlumat nəzərdə tutulur.
              </p>
            </div>
            <div className={`${styles.part1}`}>
              <p>{privacy2_Footer}</p>
              <h3>Saytın istifadəçisi </h3>
              <p>
                İnternet vasitəsilə Sayta giriş əldə edən və Elektron-mağazadan istifadə edən şəxs.
              </p>
            </div>
          </div>

          <div className={`${styles.right__part}`}>
            <p>
              Bu şəxsi məlumatların məxfiliyi siyasəti (bundan sonra, “Məxfilik
              Siyasəti”) www.avtopro.az vebsaytında (bundan sonra, “Sayt”)
              yerləşən,
              <strong> “Autoparts Pro” Məhdud Məsuliyyətli Cəmiyyətinə</strong>
              məxsus <strong>"AVTOPRO.AZ"</strong> (bundan sonra, “Platforma”)
              elektron-mağazadan istifadə edilən zaman İstifadəçilər haqqında
              əldə olunan şəxsi məlumatlara aid olunur.
            </p>

            <h3>
              <span>1. </span>ƏSAS ANLAYIŞLAR
            </h3>
            <p>
              1.1. Bu Məxfilik Siyasətində aşağıdakı əsas anlayışlardan istifadə
              olunur:
              <br />
              <br />
              1.1.1.
              <strong> Elektron-mağaza saytının administratorları </strong>
              (bundan sonra, “Sayt Administrasiyası”) – Platforma adından
              fəaliyyət göstərən, şəxsi məlumatların əldə olunmasını təşkil edən
              və ya həyata keçirən, şəxsi məlumatların işlənməsinin məqsədini,
              işlənməli şəxsi məlumatların tərkibini, şəxsi məlumatlarla həyata
              keçirilən hərəkətləri (əməliyyatları) təyin edən Saytın idarə
              edilməsi üzrə səlahiyyətli heyət.
              <br />
              <br />
              1.1.2. <strong>Şəxsi məlumatlar</strong> – fiziki və ya hüquqi
              şəxsə ("məlumat subyekti") aid hər hansı bir məlumat nəzərdə
              tutulur. Bura şəxsin adı, ünvan(lar)ı, telefon nömrə(lər)i, e-poçt
              ünvan(lar)I, həmin şəxsə aid hər hansı digər məlumat və ya
              məlumatlar toplusu aiddir. Bu məlumatlar bir yerdə şəxsin təyin
              oluna bilməsinə imkan verir.
              <br />
              <br />
              1.1.3. <strong> Şəxsi məlumatların işlənməsi</strong> -
              avtomatlaşdırma və ya belə vasitələrdən istifadə etmədən şəxsi
              məlumatlar üzərində aparılan istənilən hərəkət (əməliyyat) və ya
              hərəkətlər (əməliyyatlar) toplusu, həmçinin şəxsi məlumatların
              toplanması, yazılışı, sistemləşdirilməsi, yığılması, saxlanılması,
              dəqiqləşdirilməsi (yenilənmə, dəyişdirilmə), əldə olunması,
              istifadəsi, ötürülməsi (yayılması, təqdim olunması, tapılması),
              bloklaşdırılması, silinməsi, ləğv edilməsi.
              <br />
              <br />
              1.1.4. <strong> Şəxsi məlumatların qanunsuz yayılması</strong> –
              Platforma tərəfindən Şəxsi məlumatların bu Məxfilik Siyasətində
              göstərilən məqsədlərdən kənar və İstifadəçinin razılı olmadan
              yayılması, ötürülməsi, təqdim olunması.
              <br />
              <br />
              1.1.5.
              <strong>
                Saytın istifadəçisi (bundan sonra, “İstifadəçi”)
              </strong>
              – İnternet vasitəsilə Sayta giriş əldə edən və Elektron-mağazadan
              istifadə edən şəxs.
              <br />
              <br />
              1.1.6. <strong> Kukilər</strong> — Sayta daxil olduqda veb-server
              tərəfindən göndərilən və İstifadəçinin kompüterində saxlanılan
              məlumatlarının kiçik fraqmenti.
              <br />
              <br />
              1.1.7. <strong> “İP” ünvan </strong>— kompüter şəbəkəsində “İP”
              protokolu ilə qurulmuş unikal şəbəkə ünvanı qovşağı.
            </p>
            <br />

            <h3>
              <span>2. </span>ÜMUMİ MÜDDƏALAR
            </h3>
            <p>
              2.1. Bu Məxfilik Siyasəti İstifadəçi Sayta daxil olduqda, Saytda
              qeydiyyatdan keçdikdə və ya məhsulların satışı və ya alışı məqsədi
              ilə təqdim etdiyi şəxsi məlumatların Sayt tərəfindən qanunsuz
              yayılmaması və məxfi saxlanılması tələbini təmin edir.
              <br />
              <br />
              2.2. İstifadəçinin Saytdan istifadə etməsi bu Məxfilik Siyasətini
              və İstifadəçinin şəxsi məlumatlarının işlənmə şərtlərinin qəbul
              etməsidir.
              <br />
              <br />
              2.3. Məxfilik Siyasəti şərtləri ilə razılaşmasa, İstifadəçi
              Saytdan istifadəni dayandırmalıdır.
            </p>

            <br />
            <h3>

              <span>3. </span>İSTİFADƏÇİNİN ŞƏXSİ MƏLUMATLARININ İŞLƏNMƏSİNİN
              MƏQSƏDLƏRİ
            </h3>
            <p>

              3.1. "AVTOPRO.AZ” Administrasiyası aşağıdakı məqsədlər üçün
              İstifadəçinin şəxsi məlumatlarını istifadə edə bilər:
              <br /><br />

              3.1.1. Saytdan qeydiyyatdan keçmək və (və ya) "AVTOPRO.AZ"
              elektron-mağazada məhsulların onlayn qaydada satışı və ya alışı
              üçün müqavilə bağlamaq, o cümlədən Platformanın təqdim etdiyi
              digər xidmətlərdən faydalanmaq məqsədilə İstifadəçinin müəyyən
              edilməsi;
              <br /><br />

              3.1.2. İstifadəçiyə Saytı resurslarına daxil olmasını təmin etmək;
              <br /><br />


              3.1.3. İstifadəçi ilə qarşılıqlı əlaqənin qurulması, həmçinin
              xidmətin göstərilməsi, istifadəçinin sorğu və tələblərinin
              işlənməsi;
              <br /><br />


              3.1.4. Təhlükəsizliyin təmin edilməsi və dələduzluğun qarşısının
              alınması;
              <br /><br />
              3.1.5. İstifadəçinin təqdim etdiyi şəxsi məlumatların
              düzgünlüyünün və tamlığının təsdiqi;
              <br /><br />

              3.1.6. Maliyyə əməliyyatlarının doğrulanması;
              <br /><br />
              3.1.7. Saytın İstifadəçisinin sifarişin vəziyyəti ilə bağlı
              məlumatlandırılması;
              <br /><br />
              3.1.8. Saytdan istifadə zamanı yaranan maneələrlə bağlı
              İstifadəçiyə səmərəli müştəri və texniki dəstəyin verilməsi.
              <br /><br />
              3.1.9. İstifadəçinin razılığı ilə məhsul yeniləmələri, xüsusi
              təkliflər, qiymət məlumatları, xəbər bülletenləri və
              elektron-mağaza adından və ya tərəfdaşları adından digər
              məlumatları təqdim etmək;
              <br /><br />

              3.1.10. İstifadəçinin razılığı ilə reklam fəaliyyətini həyata
              keçirmək;
              <br /><br />

              3.1.11. Məhsul, yenilənmə və xidmətlərdən yararlanmaq üçün
              elektron-mağaza tərəfdaşlarının saytlarına və ya xidmətlərinə
              İstifadəçinin daxil olmasını təmin etmək;
              <br /><br />

              3.1.12. Platformanın fəaliyyətinin yaxşılaşdırılması üçün
              statistik və analitik məlumatların alınması;
              <br /><br />

              3.1.13. Platformada göstərilən xidmətlərin spektrinin
              genişləndirilməsi;
              <br /><br />
              3.1.14. Platforma və ya üçüncü şəxslərin informasiya və ya reklam
              xəbərlərinin alınması (yeni imkanlar, aksiyalar və başqa xəbərlər
              haqqında xəbərdarlıq); və
              <br /><br />
              3.1.15. Qüvvədə olan qanunvericiliyin tələblərinə riayətin
              təminatı.
            </p>
            <br />
            <h3>

              <span>4. </span>ŞƏXSİ MƏLUMATLARIN İŞLƏNMƏSİNİN ÜSULLARI VƏ
              MÜDDƏTİ
            </h3>
            <p>
              4.1. İstifadəçinin şəxsi məlumatlarının işlənməsi vaxt
              məhdudiyyəti qoyulmadan, istənilən qanuni üsul ilə, həmçinin
              avtomatlaşdırma və (ya) avtomatlaşdırma olmadan şəxsi məlumatların
              informasiya sistemində həyata keçirilir.
              <br /><br />
              4.2. İstifadəçi ehtiyac yarandığı hallarda Platforma ilə
              əməkdaşlıq edən və ya Platforma ilə bağlı olan şəxslərə Platforma
              tərəfindən məlumatın verilməsinə razılıq verir.
              <br /><br />
              4.3. İstifadəçinin şəxsi məlumatları Azərbaycan Respublikasının
              səlahiyyətli dövlət orqanlarına yalnız Azərbaycan Respublikası
              qanunvericiliyində müəyyən olunmuş qaydada təqdim oluna bilər.
              <br /><br />
              4.4. Şəxsi məlumatların itirildiyi və ya qanunsuz yayıldığı halda
              saytın Administrasiyası İstifadəçini məlumatlandırır.
              <br /><br />
              4.5. Sayt Administrasiyası İstifadəçinin şəxsi məlumatının
              məxfiliyinin saxlanılması, qeyri-qanuni və ya təsadüfi ələ keçmə,
              ləğv olunması, dəyişdirilməsi, bloklaşması, surətinin çıxarılması,
              qanunsuz olaraq yayılması və s. üçüncü şəxslərin qeyri qanuni
              hərəkətlərin qarşısını almaq üçün lazımi təşkilati və texniki
              tədbirləri həyata keçirir.
              <br /><br />
              4.6. Platforma İstifadəçinin məxfi məlumatlarının Platformanın
              təqsiri səbəbindən itirilməsi və ya qanunsuz olaraq yayılması ilə
              bağlı ziyanın ödənilməsini və ya mənfi nəticələrin qarşısını almaq
              üçün lazımi tədbirləri həyata keçirir.
            </p>
            <br />
            <h3>
              <span>5. </span>KUKİLƏR VƏ BƏNZƏR TEXNOLOGİYALAR
            </h3>
            <p>

              5.1. Platforma informasiyanın saxlanılması üçün kukilərdən
              (“cookies”) və bənzər texnologiyalardan istifadə edə bilər. Bu
              fayllar Platformanın istifadəsini sürətləndirmək, xidmətlərinin
              keyfiyyətinin və təhlükəsizliyinin artırılması, həmçinin reklam
              məqsədi ilə istifadə olunur.
              <br /><br />
              5.2. Platforma tərəfindən İstifadəçidən alınan istənilən
              informasiya, həmçinin çərəz faylların İstifadəçinin brauzerində
              yerləşdirilməsi, xəbərdarlıq vasitəsilə və İstifadəçinin icazəsi
              ilə həyata keçirilir. Platformadan istifadəni davam edərək,
              İstifadəçi çərəz fayllarının İstifadəçinin brauzerində saxlamasına
              öz icazəsini verir.
              <br /><br />
              5.3. Platforma kukilərdən və bənzər texnologiyalardan,
              İstifadəçinin brauzerinin fəallığı prosesində İstifadəçinin
              qurğusunda saxlanılan və həmçinin də, daha uzun müddətli dövr
              ərzində İstifadəçinin qurğusunda qalan başqa bənzər
              texnologiyalardan istifadə edir. İstifadəçinin belə kukilər və
              bənzər texnologiyaları bloklamaq və silmək imkanı vardır.
              <br /><br />
              5.4. Kukilər – Platformadan alınmış məlumatlar olub, İstifadəçinin
              brauzerində saxlanılır, əgər İstifadəçi təkrar olaraq həmin
              məlumatlara baş çəkərsə, Platformaya yollanır. Kukilər sistemə
              giriş və statistika yığımı üçün lazımı məlumatları yazaraq
              Platformadan istifadəni yüngülləşdirir. Kukilər özündə şəxsi
              məlumatları saxlamır.
              <br /><br />
              5.5. Bənzər texnologiyalar – brauzerdə və ya qurğuda informasiyanı
              saxlayan, "flashcookies", "HTML 5 cookies" kimi texnologiyalardır.
              Bu texnologiyalar İstifadəçinin bütün brauzerlərində işləyə bilər,
              bəzi hallarda isə tamamilə brauzerlə idarə oluna bilmirlər və
              İstifadəçi tərəfindən təyin edilmiş əlavələr və ya qurğular
              vasitəsilə bilavasitə idarəetməni tələb edə bilərlər.
              <br /><br />
              5.6. Kukilərə və bənzər texnologiyalara icazəsiz girişin qarşısını
              almaq üçün Platforma bütün təhlükəsizlik tədbirlərini görür.
              İstifadəçi də analoji təhlükəsizlik tədbirlərini görməyi öhdəsinə
              götürür.
              <br /><br />
              5.7. Platformada olan texniki xarakterli informasiya, məsələn,
              İP-ünvanlar, Platforma tərəfindən statistik və analitik məqsədlər
              üçün istifadə olunur.
              <br /><br />
              5.8. İstifadəçinin fərdi ehtiyaclarına və maraqlarına
              uyğunlaşdırılaraq verilən xidmətlərin yüksək keyfiyyətli təminatı
              məqsədi ilə Platforma İstifadəçinin sistemə son giriş
              məlumatlarını saxlayır.
            </p>
            <br />
            <h3>
              <span>6. </span>NAVİQASİYA VƏ KLİKLƏMƏ
            </h3>
            <p>

              6.1. Platformadan istifadə edərkən, baxdığınız səhifələr,
              kliklədiyiniz düymə və əlaqələrə dair naviqasiya və klik
              məlumatları, bir səhifədə qaldığınız müddət, sifariş vermə
              müddətiniz kimi məlumatları əldə etmək üçün saxlanılır. Həmin
              məlumatlar sizin kimliyinizi ortaya çıxartmır və birbaşa olaraq öz
              adınızla əlaqələndirilmir.
              <br /><br />
              6.2. Platforma üzərindən verdiyiniz sifarişlərin başa
              çatdırılması, təhlili və inkişaf etdirilməsi, Platformadan
              istifadənin artırılması, hüquqi baxımdan lazım olan hallarda
              məlumatın təmin olunması və Platformaya və ya üçüncü şəxslərə aid
              veb-saytlar üzərində diqqətinizi çəkəcək kampaniya və
              istiqamətləndirmələr üçün naviqasiya və klik məlumatlarıdan
              istifadə edilir. Bu məlumatlar Platformadan istifadənin təhlilində
              və dəstəyində də istifadə edilə bilər.
            </p>
            <br />
            <h3>
              <span>7. </span>TƏRƏFLƏRİN MƏSULİYYƏTİ
            </h3>
            <p>
              7.1. Bu Məxfilik Siyasətində, xüsusi ilə 7-ci bənddə göstərilən
              hallar istisna olmaqla, İstifadəçinin təqdim etdiyi məlumatların
              bu Məxfilik Siyasətində göstərilən məqsədlərdən kənar və
              qeyriqanuni istifadəsi nəticəsində İstifadəçiyə dəyən real zərərə
              görə Platforma Azərbaycan Respublikasının qanunvericiliyinə uyğun
              məsuliyyət daşıyır.
              <br /><br />
              7.2. Məxfi məlumatın qanunsuz yayılması zamanı Platforma aşağıdakı
              hallarda <strong> məsuliyyət daşımır:</strong>
              <br /><br />
              7.2.1. Onun itirilməsi və ya qanunsuz yayılmasından əvvəl
              ictimaiyyətə bəlli olub;
              <br /><br />
              <p> 7.2.2. Üçüncü şəxs məlumatı Saytdan əvvəl əldə edib;</p>
              <p>7.2.3. İstifadəçinin razılığı ilə yayılıb; və</p>
              7.2.4. Azərbaycan Respublikasının qüvvədə olan qanunvericiliyi ilə
              dairəsi müəyyən edilən səlahiyyətli şəxslərin rəsmi sorğusu
              əsasında tələb olunub.
              <br /><br />
              7.3. İstifadəçi onun göstərdiyi istənilən informasiyanın və
              məlumatların dəqiqliyi və düzgünlüyü üçün məsuliyyət daşıyır və
              Platformanı bununla bağlı yarana biləcək istənilən məsuliyyətdən
              qoruyur.
              <br /><br />
              7.4. Məxfilik Siyasəti yalnız www.avtopro.az saytına aiddir.
              Platforma İstifadəçinin Saytda mövcud olan keçidlərdən istifadə
              edərək daxil olacağı üçüncü şəxslərin veb-saytlarına nəzarət etmir
              və həmin veb-saytlara görə məsuliyyət daşımır.
            </p>
            <br />
            <h3>
              <span>8. </span>MÜBAHİSƏLƏRİN HƏLLİ
            </h3>
            <p>
              8.1. İstifadəçi Platforma arasında yaranan mübahisələrə dair
              məhkəməyə müraciət etməzdən əvvəl mübahisənin qarşılıqlı danışıq
              əsasında həll etməyə çalışacaqdır.
              <br /><br />
              8.2. Tərəflər arasında razılıq əldə edilmədikdə, mübahisə
              Azərbaycan Respublikasının mövcud qanunvericiliyinə uyğun olaraq,
              müvafiq məhkəmədə həll ediləcəkdir.
              <br /><br />
              8.3. Bu Məxfilik Siyasətinə və İstifadəçi ilə Platforma arasındakı
              münasibətlərə Azərbaycan Respublikasının mövcud qanunvericiliyi
              tətbiq edilir.
            </p>
            <br />
            <h3>
              <span>9. </span>ƏLAVƏ ŞƏRTLƏR
            </h3>
            <p>

              9.1. Platforma İstifadəçinin icazəsi olmadan bu Məxfilik
              Siyasətində dəyişikliklər edə bilər. Əgər İstifadəçi daxil edilmiş
              dəyişikliklərlə razı deyilsə, o Platformadan istifadəni
              dayandırmalıdır. Əgər İstifadəçi Platformadan istifadə etməyə
              davam edirsə, o bütün dəyişikliklərlə razılaşır və Məxfilik
              Siyasətinin yeni redaksiyasını bütövlükdə qəbul edir.
              <br /><br />
              9.2. Ayrı qayda nəzərdə tutulmadığı halda, yeni Məxfilik Siyasəti
              Saytda dərc olunduqdan sonra qüvvəyə minir.
              <br /><br />
              9.3. Bu Məxfilik Siyasəti ilə bağlı istənilən sual və təkliflər
              Saytın əlaqə vasitələri ilə göndərilə bilər.
              <br /><br />
              9.4. Qüvvədə olan Məxfilik Siyasəti www.avtopro.az saytında dərc
              olunmuşdur.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterPrivacy;
