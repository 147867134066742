import React, { useState } from 'react'
import "./privateinfo.css"
import { RiUserAddFill } from "react-icons/ri"
import { AiFillEyeInvisible, AiOutlineLock, AiFillEye } from "react-icons/ai"
import { HiChevronLeft } from 'react-icons/hi';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useForm } from "react-hook-form";
import { updateMe, updatePassword } from '../../../services/authService';
import { svgCheck } from '../../../assets/icons/Icons';
import Modal from '../../../modals/Modal';
import InputMask from "react-input-mask";
import CartTabPanel from '../../../components/cartTab';
import classes from "../carts.module.css"
import Menu from '../../../components/ui/menu';


const PrivateInfo = () => {
    const { user, setUser,visibleMobil,setVisibleMobil } = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [showMessage, setShowMessage] = useState(false)
    const screenSize = window.innerWidth


    const {
        register,
        handleSubmit,
    } = useForm();

    const {
        register: registerPassword,
        handleSubmit: handleSubmitPassword,
        reset: resetPassword
    } = useForm();


    const onSubmitUpdateMe = async (data) => {
        setLoading(true)
        data.phone = data.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
        try {
            const response = await updateMe(data);
            setUser(response.data)
            setErrors({});
            setShowMessage(true)
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
        } finally {
            setLoading(false)
        }

    };

    const onSubmitUpdatePassword = async (data) => {

        setLoadingPassword(true)
        try {
            await updatePassword(data);
            resetPassword();
            setErrors({});
            setShowMessage(true)
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors ? error.response.data.errors : { password: error.response.data.message })
            }
        } finally {
            setLoadingPassword(false)
        }

    };

    const closeModal = () => {
        setShowMessage(false)
    };

   
    const handleLeftClick=()=>{
        setVisibleMobil(!visibleMobil)
      }

    return (
         <>
           <Menu/>
           <section className={`${classes.carts__container} container`}>
            <CartTabPanel activeTab='privateinfo' />
            <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
                <div className='mobil__title__cart'>
                    <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
                    <h3>Şəxsi məlumat</h3>
                    <span></span>
                </div>
                <div className='privateInfo__container'>
                    <form onSubmit={handleSubmit(onSubmitUpdateMe)}>
                        <h3>
                            <RiUserAddFill />
                            Şəxsi məlumat
                        </h3>
                        <div className="privateForm">
                            <span>Ad</span>
                            <input type="text" id='name' defaultValue={user?.name} {...register("name")} />
                            {errors?.name && <p className='dangerText'>{errors?.name}</p>}
                        </div>
                        <div className="privateForm">
                            <span>Soyad</span>
                            <input type="text" id="surname" defaultValue={user?.surname} {...register("surname")} />
                            {errors?.surname && <p className='dangerText'>{errors?.surname}</p>}
                        </div>
                        <div className="privateForm">
                            <span>E-mail</span>
                            <input type="email" id="email" defaultValue={user?.email} {...register("email")} />
                            {errors?.email && <p className='dangerText'>{errors?.email}</p>}
                        </div>
                        <div className="privateForm">
                            <span>Mobil</span>
                            <InputMask mask="(999) 999-99-99" defaultValue={user?.phone} placeholder="(050) 333-xx-xx" {...register('phone')} />
                            {errors?.phone && <p className='dangerText'>{errors?.phone}</p>}
                        </div>
                        <button type="submit" className='btn btn__primary mobil__none'>{loading ? (<>Göndərilir &nbsp;	 <i className="fas fa-circle-notch fa-spin"></i></>) : "Yadda saxla"}</button>
                    </form>
                    {/* <form>
                    <h3>
                        <AiOutlineLock />
                        Avtomobilinizin məlumatları
                    </h3>
                    <div className="privateForm">
                        <span>Avtomobilinizin növü</span>
                        <input type="text" placeholder='Arif' />
                    </div>
                    <div className="privateFormSelect">
                        <span>Marka</span>
                        <Select
                            className='selectItems'
                            placeholder={'Mercedes-Benz '}
                            options={marka}
                        />
                    </div>
                    <div className="privateFormSelect">
                        <span>Model</span>
                        <Select
                            className='selectItems'
                            placeholder={'Mercedes-Benz '}
                            options={marka}
                        />
                    </div>
                    <div className="privateFormSelect">
                        <span>İstehsal tarixi</span>
                        <Select
                            className='selectItems'
                            placeholder={'Mercedes-Benz '}
                            options={marka}
                        />
                    </div>
                    <button className='btn btn__primary mobil__none'>Yadda saxla</button>
                </form> */}
                    <form onSubmit={handleSubmitPassword(onSubmitUpdatePassword)}>
                        <h3>
                            <AiOutlineLock />
                            Şifrə
                        </h3>
                        <div className="privateForm">
                            <span>İndiki şifrəniz</span>
                            <input type={!showPassword ? "password" : "text"}  {...registerPassword("password")} />
                            {errors?.password && <p>{errors?.password}</p>}
                            {!showPassword ? <AiFillEyeInvisible style={eye} onClick={() => setShowPassword(!showPassword)} /> :
                                <AiFillEye style={eye} onClick={() => setShowPassword(!showPassword)} />
                            }
                        </div>
                        <div className="privateForm">
                            <span>Yeni şifrə</span>
                            <input type="text" placeholder='' {...registerPassword("new_password")} />
                            {errors?.new_password && <p>{errors?.new_password}</p>}
                        </div>
                        <div className="privateForm">
                            <span>Yeni şifrənin təkrarı</span>
                            <input type="text"   {...registerPassword("new_password_confirmation")} />
                            {errors?.new_password_confirmation && <p>{errors?.new_password_confirmation}</p>}
                        </div>

                        <button className='btn btn__primary mobilW100' >{loadingPassword ? (<>Göndərilir &nbsp;	 <i className="fas fa-circle-notch fa-spin"></i></>) : "Yadda saxla"}</button>
                    </form>
                </div>

                {showMessage && <Modal isOpen={showMessage} onClose={closeModal} width={340} height={screenSize > 600 ? 28 : 30}>
                    <div className={`  modalCart modalCartFont`}>
                        <p>{svgCheck}</p>
                        <h3> Ugurlu emelyat</h3>
                    </div>
                </Modal>}

            </div>
        </section>
         </>
    )
}

export default PrivateInfo



// const marka = [
//     { value: 'Mercedes-Benz ', label: 'Mercedes-Benz ' },
//     { value: 'bmw', label: 'bmw' },
//     { value: 'audi', label: 'audi' }
// ]

const eye = {
    position: "absolute",
    right: "1.5rem",
    top: "55%"
}