import React, { useEffect, useState } from 'react';
import classes from './banner.module.css';
import ban1mob from "../../assets/images/ipoteka.png"
import ban2 from '../../assets/images/ban2.png';
import ban2mob from '../../assets/images/mob.png';
import ban3 from '../../assets/images/avtodetal.png';
import ban3mob from '../../assets/images/avtodetalmob.png';
import ban4 from "../../assets/images/11q.png"
import ban4mob from "../../assets/images/22q.png"
import ban5 from '../../assets/images/mazda.png';
import ban5mob from '../../assets/images/mazdamob.png';
import ban6 from '../../assets/images/airmiles1.png';
import ban6mob from '../../assets/images/airmilesmob1.png';
import ban7 from '../../assets/images/airmiles2.png';
import ban7mob from '../../assets/images/airmilesmob2.png';
import ban8 from '../../assets/images/valvoline.jpg';
import ban8mob from '../../assets/images/valvolinemob.jpg';
import ban9 from '../../assets/images/netcon.jpg';
import ban9mob from '../../assets/images/netconmob.jpg';
import ban10 from '../../assets/images/autolux.jpg';
import ban10mob from '../../assets/images/autoluxmob.jpg';
import { Link } from 'react-router-dom';

const images = [

  {
    link: 'https://autolux.az/',
    src: ban10,
    srcMobile: ban10mob,
    alt: 'Autolux',
  },
  {
    link: 'https://www.instagram.com/netconaz?igsh=MWUzYzNjZm1scTh3eQ%3D%3D',
    src: ban9,
    srcMobile: ban9mob,
    alt: 'Netcon',
  },
  {
    link: 'https://www.valvoline.az/',
    src: ban8,
    srcMobile: ban8mob,
    alt: 'Valvoline',
  },
  {
    link: 'https://www.premiumbank.az/',
    src: ban7,
    srcMobile: ban7mob,
    alt: 'Premiumbank',
  },
  {
    link: 'https://www.premiumbank.az/',
    src: ban6,
    srcMobile: ban6mob,
    alt: 'Premiumbank',
  },
  {
    link: 'https://mazda.az/',
    src: ban5,
    srcMobile: ban5mob,
    alt: 'Mazda',
  },
  {
    link: 'https://mercedes-autostar.az',
    src: ban4,
    srcMobile: ban4mob,
    alt: 'Mercedes Autostar',
  },
  {
    link: 'https://avtopro.az/shops/285?shop_type=2&shop_id=285',
    src: ban3,
    srcMobile: ban3mob,
    alt: 'AvtoDetal',
  }

];

const BannerSlider = () => {
  const screenSize = window.innerWidth;
  const [imageIndex, setImageIndex] = useState(Math.floor(Math.random() * images.length));

  const currentImage = screenSize > 600 ? images[imageIndex].src : images[imageIndex].srcMobile;

  const changeImage = () => {
    let newIndex = Math.floor(Math.random() * images.length);
    if(newIndex === imageIndex) {
      newIndex = (newIndex + 1) % images.length;
    }
    setImageIndex(newIndex);
  };

  useEffect(() => {
    changeImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <section className={`${classes.banner} mobilNone`}>
      <Link to={images[imageIndex].link} target="_blank">
        <img src={currentImage} alt={images[imageIndex].alt} />
      </Link>
    </section>
  );
};

export default BannerSlider;
