import React, { useState } from 'react'
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import "./productvideo.css"
import VideoPopup from './videoPopup';
import { GrYoutube } from 'react-icons/gr';
import Menu from '../../components/ui/menu';
import { useYoutube } from '../../hooks/query/youtube';
import Meta from '../../components/ui/meta';

const ProductVideo = () => {
  const page = {
    pages: [
      {
        link: "/video",
        content: "Youtube",
      }
    ],
  };

  const image_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage";

  const { data } = useYoutube()


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [video, setVideo] = useState('')

  const openModal = (url) => {
    setVideo(url)
    setIsModalOpen(true);
    setPlay(true)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPlay(false)
  };

  const [play, setPlay] = useState(false);
  const url = play
    ? `${video}?autoplay=1&&controls=0&modestbranding=1&showinfo=0`
    : ``;

  const regex = /\/shorts\/([a-zA-Z0-9_-]+)\??/;
  const shortUrlRegex = /youtu.be\/([a-zA-Z0-9_-]+)\??/i;
  const longUrlRegex = /youtube.com\/((?:embed)|(?:watch))((?:\?v=)|(?:\/))([a-zA-Z0-9_-]+)/i;


  const title = "Avtopro - Youtube video | avtopro.az";
  const keywords = "video, avtomobil, balans, razval, xodovoy təmiri, mühərrik təmiri, yağlama, çilingər, elektrik, diaqnostika, fara təmiri, dəmirci"
  const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";


  return (
    <>
      <Meta title={title} keywords={keywords} description={description} />
      <Menu />
      <Breadcrumb page={page} />
      <section className={`container `}>
        <div className='video-title'>
          <GrYoutube color="#FE0000" fontSize="2rem" /> HUB
        </div>
        <div className={`video__container`}>
          {
            data?.data?.map((item) => {

              let videoId = ''
              if(item?.url?.match(regex)) {
                videoId = item?.url?.match(regex)[1];
              } else if(item?.url?.match(shortUrlRegex)) {
                videoId = item?.url?.match(shortUrlRegex)[1];
              } else if(item?.url?.match(longUrlRegex)) {
                videoId = item?.url?.match(longUrlRegex)[3];
              }

              return (
                <article key={item.id}>
                  <div onClick={() => openModal(videoId ? `https://www.youtube.com/embed/${videoId}` : item?.url)} className='video-overlay' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image_url}/${item.cover})`, }} >
                    <h4>{item.title}</h4>
                    <span><GrYoutube color="#FE0000" fontSize="2rem" /> Youtube</span>
                  </div>
                  <iframe src={videoId ? `https://www.youtube.com/embed/${videoId}` : item.url}
                    frameborder="0"
                    title="myFrame"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </article>
              )
            })
          }

        </div>
      </section>

      <VideoPopup isOpen={isModalOpen} onClose={closeModal}>
        <article  >
          <iframe src={url} frameborder="0"
            title="myFramePopup"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </article>
      </VideoPopup>

    </>
  )
}

export default ProductVideo