import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/shop-card.css";

const Shopcard = ({ shop }) => {
    const image_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/" + shop.logo;

    const siteURL = "https://avtopro.az"
    const { pathname } = useLocation();
    const pageLink = pathname + "/" + shop.id;
    console.log(shop);
    // const shopAddress = data?.data?.shop_information;
    // const shopAddress = shop?.shop_information[0]?.address;
    // const shopPhone = shop?.shop_information[0]?.phone1;
    // const phoneClear = shopPhone;
    // let reversedString = "";
    // let resultPhone = "";

    // if(phoneClear == "" || phoneClear == null || phoneClear == undefined) {
    // } else {
    //     reversedString = phoneClear.replace(/\s|\(|\)/g, '').split('').reverse().slice(0, 9).reverse().join('');
    //     resultPhone = "+994" + reversedString;
    // }

    const postalSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": `${shop?.name}`,
        "url": `${siteURL + pageLink}`,
        "logo": `${image_url}`,
        "description": `${shop?.name} - mağazada məhsulların satışı avtopro.az'da"`,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "-",
            "addressLocality": "-",
            "addressRegion": "-",
            "postalCode": "-",
            "addressCountry": "Azerbaijan"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "-",
            "contactType": "customer service"
        }

    };

    return (
        <article className="shopCard auto_shop_card">
            <Link to={`/autosalon/${shop.id}?shop_type=${3}&shop_id=${shop.id}`} className="shopCard__link">
                <div className="shopCard_cropimg">
                    <img src={image_url} alt={shop.name} />
                </div>
            </Link>
            <h3>{shop.name}</h3>
            <p className="shopCard__count">
                <span>
                    {shop.products_count > 0 ? `${shop.products_count} Məhsul` : 'Məhsullar yüklənilir'}
                </span>
            </p>
            <script type="application/ld+json">{JSON.stringify(postalSchema)}</script>
        </article>

        // shop.shop_type === 3 ? (
        //     <article className="shopCard">
        //         <Link to={`/shops/${shop.id}?shop_type=2&shop_id=${shop.id}`} className="shopCard__link">
        //             <img src={image_url} alt={shop.name} />
        //         </Link>
        //         <h3>{shop.name}</h3>
        //         <p className="shopCard__count">
        //             <span>
        //                 {shop.products_count > 0 ? `${shop.products_count} Məhsul` : 'Məhsullar yüklənilir'}
        //             </span>
        //         </p>
        //         <script type="application/ld+json">{JSON.stringify(postalSchema)}</script>
        //     </article>
        // ) : (
        //     console.log('') || null

    );
};

export default memo(Shopcard);
