import React, { useEffect, useState } from "react";
import Meta from "../../components/ui/meta";
import Menu from "../../components/ui/menu";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Filter from "../../components/ui/filter/filter";
import Product from "../../components/product/product";
import "./products.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Mobilfilter from "../../components/ui/mobilfilter/mobilfilter";
import { getProducts } from "../../services/productService";
import { useInfiniteQuery } from "@tanstack/react-query";
import ParentCategory from "../../components/parentCategory";
import MobilWheelfilter from "../../components/mobil_wheel_filter/mobil_wheel_filter";
import WheelRimsFilter from "../../components/wheel_filter/wheel_rims_filter";

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [orderSelectValue] = useState()

  const [catgName, setCatgName] = useState('')
  const [queryItem, setQueryItem] = useState('')

  const params = {
    paginate: 30,
    page: searchParams.get("page") ?? 1,
    car_make_id: searchParams.get("car_make_id") ?? 0,
    car_model_id: searchParams.get("car_model_id") ?? 0,
    car_generation_id: searchParams.get("car_generation_id") ?? 0,
    height_id: searchParams.get("height_id") ?? 0,
    diameter_id: searchParams.get("diameter_id") ?? 0,
    width_id: searchParams.get("width_id") ?? 0
  }

  if(searchParams.get("category_id")) params["category_id"] = searchParams.get("category_id");
  if(searchParams.get("search")) params["search"] = searchParams.get("search");
  if(searchParams.get("product_number")) params["product_number"] = searchParams.get("product_number");
  if(searchParams.get("order")) params["order"] = searchParams.get("order");
  if(searchParams.get("direction")) params["direction"] = searchParams.get("direction");
  if(searchParams.get("shop_id")) params["shop_id"] = searchParams.get("shop_id");
  if(searchParams.get("shop_type")) params["shop_type"] = searchParams.get("shop_type");
  if(searchParams.get("height_id")) params["height_id"] = searchParams.get("height_id");
  if(searchParams.get("diameter_id")) params["diameter_id"] = searchParams.get("diameter_id");
  if(searchParams.get("width_id")) params["width_id"] = searchParams.get("width_id");


  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['products_all', params],
    ({ pageParam = 1 }) => getProducts({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  );

  const page = {
    pages: [
      {
        link: "/products?page=1",
        content: `Kateqoriyalar`,
      },
      {
        link: "/products?page=1",
        content: `${catgName}`,
      }
    ]
  };
  const handleOrder = (e) => {
    const queryParams = Object.fromEntries(searchParams.entries());
    switch(e.value) {
      case 1:
        queryParams.order = "id";
        queryParams.direction = "desc";

        break;
      case 2:
        queryParams.order = "price";
        queryParams.direction = "desc";
        break;
      case 3:
        queryParams.order = "price";
        queryParams.direction = "asc";
        break;
      case 4:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 2;
        break;
      case 5:
        delete queryParams.order;
        delete queryParams.direction;
        queryParams.shop_type = 1;
        break;
      default:
        queryParams.order = "id";
        queryParams.direction = "desc";
        break;
    }

    const updatedSearchParams = new URLSearchParams(queryParams);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  }

  const wheelFilter = Number(searchParams.get("category_id"));


  const wheelLists = [45, 44]
  const wheelFilterShow = wheelLists.find((item) => item === wheelFilter)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const savedCatgName = localStorage.getItem('catgName');
  //   if(savedCatgName) {
  //     setCatgName(savedCatgName);
  //   }
  // }, []);

  useEffect(() => {
    const currentCatgName = localStorage.getItem('catgName');
    setCatgName(currentCatgName);
  }, [searchParams]);

  const title = `Kategoriyalar - ${catgName} | avtopro.az`
  const keywords = `${catgName}, kategoriyalar, zapcast, avtomobil, nəqliyyat, nəqliyyat vasitəsi, avtopro, aksesuar, təkərlər, əyləc bəndi, yağlar`
  const description = `${(catgName ? catgName : "") + ' - nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı| avtopro.az'}`;
  console.log(data);
  return (
    <>
      <Meta title={title} keywords={keywords} description={description} />
      <Menu />
      <Breadcrumb page={page} />

      <ParentCategory />

      <section className="products__container">
        {!wheelFilterShow && <Mobilfilter categoryId={searchParams.get("shop_type") ?? 0} />}
        {!wheelFilterShow && <Filter categoryId={searchParams.get("shop_type") ?? 0} />}
        {wheelFilterShow && <WheelRimsFilter />}
        {wheelFilterShow && <MobilWheelfilter />}
        <Product
          end="end"
          orderSelectValue={orderSelectValue}
          handleOrder={handleOrder}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          error
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </section>
    </>
  );
};

export default Products;


