import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./orderlist.css"
import { FiChevronDown } from "react-icons/fi"
import { FiChevronUp } from "react-icons/fi"
import { CheckBox } from '../../../assets/icons/Icons'
import { HiChevronLeft } from 'react-icons/hi'
import { getOrderProducts } from '../../../services/orderService'
import { useInfiniteQuery } from '@tanstack/react-query'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import Menu from '../../../components/ui/menu'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import Meta from "../../../components/ui/meta";

const OrderList = () => {
  const { visibleMobil, setVisibleMobil } = useContext(AuthContext)
  const [stepActive, setStepActive] = useState(null);
  const [selectedStatuses, setSelectedStatuest] = useState([1, 2, 4, 5, 6]);
  const [active, setActive] = useState("all")



  const statuses = {
    1: 'Sifariş verilib',
    2: 'Təstiq edilib',
    3: 'Kuryerdədir',
    4: 'Təhvil verilib',
    5: 'Ləğv edildi',
    6: 'Qeri qaytarıldı',
  }

  const openStatuses = {
    1: 'Sifariş verilib',
    2: 'Təstiq edilib',
    3: 'Kuryerdədir',
    4: 'Təhvil verilib'
  }

  const queryParams = { paginate: 10, status: selectedStatuses }
  const showOrder = (key) => {

    if(key === "all") {
      setSelectedStatuest([1, 2, 4, 5, 6]);
      setActive("all")
    } else if(key === "cancel") {
      setSelectedStatuest([5]);
      setActive("cancel")
    } else if(key === "returns") {
      setSelectedStatuest([6]);
      setActive("returns")
    }
  }


  const {
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['orders-products', queryParams],
    ({ pageParam = 1 }) => getOrderProducts({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return lastPage?.data?.length !== 0 ? nextPage : undefined
      },
    }
  )


  useEffect(() => {
    let fetching = false;

    const handleScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;

      if(!fetching && scrollHeight - scrollTop <= clientHeight * 1.2 && hasNextPage) {
        fetching = true;
        const newData = await fetchNextPage(); // This calls the API to fetch the next page.

        const fetchDataTrue = newData?.data?.pages?.map((item) => item?.data?.data)
        const fetchData = fetchDataTrue?.find((item) => item?.length < 30)
        if(fetchData) {
          document.removeEventListener('scroll', handleScroll);
        }

        fetching = false;
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, selectedStatuses]);


  const image_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/";

  const handleLeftClick = () => {
    setVisibleMobil(!visibleMobil)
  }

  const currentUrl = window.location.href;
  const title = 'Avtopro - Sifariş edilmiş məhsullar'
  const keywords = "sifariş məhsullar, məhsul sifarişi, avtomobil, nəqliyyat, nəqliyyat vasitəsi, zapcast, vin, oem, avtopro, aksesuar"
  const description = "Nəqliyyat vasitələrinə aid hər növ ehtiyyat hissələri və aksesuarların satışı avtopro.az'da";

  return (
    <>
      <Meta title={title} keywords={keywords} description={description} currentUrl={currentUrl} />
      <Menu />
      <section className={`${classes.carts__container} container`}>
        <CartTabPanel activeTab='orderlist' />
        <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
          <div className='mobil__title__cart'>
            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
            <h3> Sifarişlərim</h3>
            <span></span>
          </div>
          <div className='order-list-tabs'>
            <button className={`btn btn__light ${active === "all" ? "activeLightBtn" : ''}`} onClick={() => showOrder("all")}>Bütün sifarişlər</button>
            <button className={`btn btn__light ${active === "cancel" ? "activeLightBtn" : ''}`} onClick={() => showOrder("cancel")}>Ləğv edilənlər</button>
            <button className={`btn btn__light ${active === "returns" ? "activeLightBtn" : ''}`} onClick={() => showOrder("returns")}>Qaytarılanlar</button>
          </div>
          <div className='order-list-cards'>
            {data?.pages?.map((page) =>
              page?.data?.data?.map((order) => {

                const dateTimeString = order?.created_at;

                const date = new Date(dateTimeString);

                const formattedDate = date.toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour12: false
                });

                let activeStatus;
                const isActive = statuses[order.status];
                if(isActive === 'Sifariş verilib') {
                  activeStatus = 'success'
                } else if(isActive === 'Ləğv edildi') {
                  activeStatus = 'danger'
                } else if(isActive === 'Qeri qaytarıldı') {
                  activeStatus = 'return'
                }

                return (
                  <div className='order-list-item' key={order?.id}>
                    <Link className='order-list-item-img'>
                      <img src={image_url + order?.product?.cover} alt={order?.product?.product_name} />
                      <p className={`order-item-info ${activeStatus}`}>{statuses[order.status] ?? ""}</p>
                    </Link>
                    <div className='order-list-item-info'>
                      <table>
                        <thead>
                          <tr>
                            <th>Sifariş kodu</th>
                            <th>Məhsul</th>
                            <th>OEM kod</th>
                            <th>Mağaza</th>
                            <th>Ödənildi</th>
                            <th>Tarix</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{order?.order_id}</td>
                            <td>{order?.product?.product_name}</td>
                            <td>
                              {
                                order?.product?.product_numbers.map(item => (<> {item?.part_number} <br /> </>))
                              }
                            </td>
                            <td>{order?.shop?.name}</td>
                            <td>{order?.count * Number(order?.product_price)} AZN</td>
                            <td>{formattedDate}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='info-mobil'>
                        <h5>{order?.product?.product_name}</h5>
                        <p>{order?.shop?.name}</p>
                        <p>Sifariş kodu: <strong>{order?.order_id}</strong></p>
                        <p>OEM kod: <strong>{
                          order?.product?.product_numbers.map(item => (<> {item?.part_number} <br /> </>))
                        }</strong></p>
                        <div style={flex}>
                          <p>Ödənildi <strong>{order?.count * Number(order?.product_price)} AZN</strong></p>
                          <p>Tarix <strong>{formattedDate}</strong></p>
                        </div>
                      </div>
                      {active === "all" && <div className={stepActive === order.id ? "activeSteps steps" : "steps"} >

                        {Object.entries(openStatuses).map(([key, value]) => (
                          <div className='step-item' key={key}>
                            <button className={`step ${Number(key) === order.status ? 'activeStep' : ''}`}>
                              {CheckBox}
                            </button>
                            <div className='step-line'></div>
                            <p>{value}</p>
                            {/* <span>{formattedDate}</span> */}
                          </div>
                        ))}
                      </div>}
                    </div>
                    {active === "all" && (
                      <button className='downIcon' onClick={() => setStepActive(stepActive === order.id ? null : order.id)}>
                        {stepActive === order.id ? <FiChevronUp /> : <FiChevronDown />}
                      </button>
                    )}

                  </div>
                )
              })
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default OrderList



const flex = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "2rem"
}